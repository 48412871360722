"use client"

import { useRouter } from "next/navigation";
import { useEffect } from "react";
import type { Locale } from "@configs/i18n";
import type {ChildrenType} from "@core/types";

export default function PrefetchStaticPaths({ children, locale }: ChildrenType & { locale: Locale }) {
    const router = useRouter();

    useEffect(() => {
        const prefetchPaths = async () => {
            const paths = [
                `http://localhost:3010/${locale}/PannelloAdmin/`,
                `http://localhost:3010/${locale}/PannelloImpostazioni/`,
                `http://localhost:3010/${locale}/PannelloUtenti/`,
                `http://localhost:3010/${locale}/PannelloRuoli/`,
                `http://localhost:3010/${locale}/Register/`,
                `http://localhost:3010/${locale}/login/`,
            ] // list of paths that you want to prefetch

            for (const path of paths) {
                await router.prefetch(path);
            }
        };

        prefetchPaths();
    }, [router, locale]);

    return <>{children}</>;
};